import { IItemsAndroListObject } from 'sharedModels';

export class ConfirmationDialogOptions {
  title: string;
  message?: string;
  clientObject?: Array<IItemsAndroListObject>;
  dialogType?: 'Warning' | 'Alert';
  btnOkText?: string;
  btnCancelText?: string;
  dialogSize?: 'sm' | 'lg' | 'xl';
}
