import { ScrollSpyDirective } from './scroll-spy/scroll-spy.directive';
import { AndroLengthValidatorDirective } from './length-validator/length-validator.directive';
import { NumberDirective } from './numbers-only/numbers-only.directive';
import { AutofocusDirective } from './autofocus/autofocus.directive';
import { ValidationDateDirective } from './validation-date/validation-date.directive';
import { SpliceSpacesDirective } from './split-spaces/split-spaces.directive';
import { RangeValidatorDirective } from './range/range-validator.directive';
import { DecimalDirective } from './decimals-only/decimals-only.directive';

export const directives: any[] = [
  ScrollSpyDirective,
  AndroLengthValidatorDirective,
  NumberDirective,
  AutofocusDirective,
  ValidationDateDirective,
  SpliceSpacesDirective,
  RangeValidatorDirective,
  DecimalDirective
];

export * from './scroll-spy/scroll-spy.directive';
export * from './length-validator/length-validator.directive';
export * from './numbers-only/numbers-only.directive';
export * from './autofocus/autofocus.directive';
export * from './validation-date/validation-date.directive';
export * from './split-spaces/split-spaces.directive';
export * from './range/range-validator.directive';
