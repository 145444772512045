<div class="andro-form-group" [class.required]="required">
  <label class="tu-form-label">{{label}}</label>
  <div #drop_reference>

    <input *ngIf="autoCompleat && dropdown?.showing" #input placeholder={{placeholder}} [disabled]="disabled"
      name="autoCompleatValue" [(ngModel)]="autoCompleatValue" autocomplete="off" class="andro-form-input"
      [ngClass]="{'andro-form-input-error': error}" (ngModelChange)="valueChange.emit(autoCompleatValue)"
      (keyup)="onKeyDown($event)">

    <div *ngIf="!autoCompleat || autoCompleat && !dropdown?.showing" class="andro-form-select"
      [ngClass]="{'andro-form-input-error': error, 'disabled': disabled}" [hidden]="templateOptionSelected"
      (click)="showDropdown(drop_reference)">
      <h1>{{displayText}}</h1>
    </div>

  </div>

  <andro-custom-dropdown *ngIf="reference" [reference]="reference" #dropdownComp>
    <div #dropdown_options_container class="dropdown-options-container" id="dropdown_options_container">
      <ng-content select="andro-custom-select-option"></ng-content>
    </div>
  </andro-custom-dropdown>
</div>
