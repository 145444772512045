import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { APP_CONFIG, AppConfig } from '../../../app-config/app-config.module';
import { StorageService } from '../../storage/storage.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { HttpRequestOptions, HttpRequestParams } from '../../../models/index';

export enum HttpRequestMethod {
  Get = 'GET',
  Post = 'POST',
  put = 'PUT',
  Patch = 'PATCH',
  Delete = 'DELETE'
}

@Injectable()
export class BaseHttpService {
  protected httpRequestParams = new HttpRequestParams<any>();

  constructor(
    @Inject(APP_CONFIG) protected config: AppConfig,
    protected storageService: StorageService,
    protected http: HttpClient
  ) { }

  protected getRequestUrl(endPoint: string) {
    return `${this.config.baseManagementUrl}${this.config.apiUrl}${endPoint}`;
  }

  protected getHeaders(authorize?: boolean): HttpHeaders {
    let headers = new HttpHeaders({ Accept: 'application/json' });
    if (authorize) {
      headers = headers.set(
        'authorization',
        'Bearer ' + this.storageService.accessToken,
      );
    }
    return headers;
  }

  protected urlParamsParser(url: string, param?: string | number): string {
    if (param) {
      url = `${url}/${param}`;
    }
    return url;
  }

  protected queryParamsParser(
    queryParams: string | { [key: string]: string | string[] }
  ): HttpParams {
    if (!queryParams) {
      return;
    }
    return new HttpParams(
      typeof queryParams === 'string'
        ? { fromString: queryParams }
        : { fromObject: queryParams }
    );
  }

  private request<T>(
    method: string,
    endPoint: string,
    options: HttpRequestOptions,
    authorize?: boolean
  ) {
    return this.http.request<T>(method, this.getRequestUrl(endPoint), {
      ...options,
      headers: this.getHeaders(authorize)
    });
  }

  protected get<T>(
    endPoint: string,
    authorize: boolean,
    httpRequest?: HttpRequestParams<any>
  ): Observable<T> {
    return this.request<T>(
      HttpRequestMethod.Get,
      this.urlParamsParser(
        endPoint,
        httpRequest ? httpRequest.urlParams : null
      ),
      {
        params: this.queryParamsParser(
          httpRequest ? httpRequest.queryParams : null
        )
      },
      authorize
    );
  }

  protected post<T>(
    endPoint: string,
    authorize: boolean,
    httpRequest: HttpRequestParams<any>
  ): Observable<T> {
    return this.request<T>(
      HttpRequestMethod.Post,
      this.urlParamsParser(
        endPoint,
        httpRequest ? httpRequest.urlParams : null
      ),
      {
        params: this.queryParamsParser(
          httpRequest ? httpRequest.queryParams : null
        ),
        body: httpRequest ? httpRequest.payload : null,
        responseType: httpRequest.responseType ? httpRequest.responseType : 'json'
      },
      authorize
    );
  }

  protected put<T>(
    endPoint: string,
    authorize: boolean,
    httpRequest: HttpRequestParams<any>
  ): Observable<T> {
    return this.request<T>(
      HttpRequestMethod.put,
      this.urlParamsParser(
        endPoint,
        httpRequest ? httpRequest.urlParams : null
      ),
      {
        params: this.queryParamsParser(
          httpRequest ? httpRequest.queryParams : null
        ),
        body: httpRequest ? httpRequest.payload : null
      },
      authorize
    );
  }

  protected patch<T>(
    endPoint: string,
    authorize: boolean,
    httpRequest: HttpRequestParams<any>
  ): Observable<T> {
    return this.request<T>(
      HttpRequestMethod.Patch,
      this.urlParamsParser(
        endPoint,
        httpRequest ? httpRequest.urlParams : null
      ),
      {
        params: this.queryParamsParser(
          httpRequest ? httpRequest.queryParams : null
        ),
        body: httpRequest ? httpRequest.payload : null
      },
      authorize
    );
  }

  protected delete<T>(endPoint: string, authorize: boolean, httpRequest?: HttpRequestParams<any>): Observable<T> {
    return this.request<T>(
      HttpRequestMethod.Delete,
      this.urlParamsParser(endPoint),
      {
        params: this.queryParamsParser(
          httpRequest ? httpRequest.queryParams : null
        ),
        body: httpRequest ? httpRequest.payload : null
      },
      authorize
    );
  }

  protected buildQueryParams(source: any) {
    if (source) {
      Object.keys(source).forEach((key: string) => {
        const value: string | number | boolean | Date = source[key];
        if ((typeof value !== 'undefined') && (value !== null)) {
          source[key] = value.toString();
        }
        if (source[key] === null || (typeof value === 'undefined') || source[key].length === 0) {
          source[key] = '';
        }
      });
      return source;
    } else {
      return {};
    }
  }

}
