import { NgModule, ModuleWithProviders, LOCALE_ID } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';

// Module
import { NgbModule, NgbDatepickerI18n, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateNativeAdapter } from '@ng-bootstrap/ng-bootstrap';

// cdk
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';

// services
import * as fromServices from './services';

// Directives
import * as fromDirectives from './directives';

// Components
import * as fromComponents from './components';
import { AuthService } from './services/api/auth/auth.service';


@NgModule({
  declarations: [...fromDirectives.directives, ...fromComponents.components],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    TranslateModule,
    ReactiveFormsModule,
    NgbModule,
    OverlayModule,
    PortalModule

  ],
  providers: [],
  exports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    NgbModule,
    OverlayModule,
    PortalModule,

    ...fromComponents.components,
    ...fromDirectives.directives,
  ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        fromServices.StorageService,
        fromServices.SharedService,
        fromServices.UploadService,
        fromServices.ConfirmationDialogService,
        { provide: HTTP_INTERCEPTORS, useClass: fromServices.HttpConfigInterceptor, multi: true },
        DatePipe,
        fromServices.AuthGuardService,
        AuthService,
        // Ng Bootstrap
        { provide: NgbDatepickerI18n, useClass: fromServices.DatepickerI18nService },
        { provide: NgbDateParserFormatter, useClass: fromServices.NgbDateCustomParserFormatter },
        { provide: LOCALE_ID, useValue: 'fr-FR' },
        NgbDateNativeAdapter

      ]
    };
  }
}
