<div class="andro-list-object">
  <div class="andro-list-object-items">
    <div class="andro-item" *ngFor="let item of items" >
      <h6 class="andro-item-title">{{item.title | translate}}</h6>
      <h6 class="andro-item-value">{{item.value}}</h6>
    </div>
    <ng-container>
      <ng-content></ng-content>
    </ng-container>
  </div>
</div>