import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'andro-root',
  template: `<router-outlet></router-outlet>`
})
export class AppComponent implements OnInit {
  title = 'andro-front-back-office';
  constructor(private translate: TranslateService) {
  }

  ngOnInit() {
    this.translate.setDefaultLang('fr');
  }
}
