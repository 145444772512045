import { HttpClient } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';

import { BaseHttpService } from '../base/base-http.service';
import { HttpRequestParams } from 'sharedModels';
import { APP_CONFIG, AppConfig } from 'sharedConfig';
import { StorageService } from '../../storage/storage.service';

@Injectable()
export class SharedService extends BaseHttpService {


  constructor(
    @Inject(APP_CONFIG) protected config: AppConfig,
    protected storageService: StorageService,
    protected http: HttpClient
  ) {
    super(config, storageService, http);
  }

  // Base crud
  getContainer<T>(containerId: number, container: string, params?) {
    const url = container + '/' + containerId + '/';
    const httpRequestParams = new HttpRequestParams();
    httpRequestParams.queryParams = params;
    return this.get<T>(url, true, httpRequestParams);
  }

  getContainers(container: string, params?) {
    const httpRequestParams = new HttpRequestParams();
    const url = container;
    httpRequestParams.queryParams = params;
    return this.get<any>(url, true, httpRequestParams);
  }

  postContainer<T>(containerObject: any, container: string) {
    this.httpRequestParams = new HttpRequestParams();
    const url = container;
    this.httpRequestParams.payload = containerObject;
    return this.post<T>(url, true, this.httpRequestParams);
  }

  putContainer<T>(containerObject: any, containerId: number, container: string) {
    this.httpRequestParams = new HttpRequestParams();
    const url = container + '/' + containerId;
    this.httpRequestParams.payload = containerObject;
    return this.put<T>(url, true, this.httpRequestParams);
  }

  deleteContainer<T>(containerId, container: string) {
    this.httpRequestParams = new HttpRequestParams();
    this.httpRequestParams.payload = containerId;
    const url = container;
    return this.delete<T>(url, true, this.httpRequestParams);
  }

}
