import { Directive, forwardRef, Attribute } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';

@Directive({
  selector: '[androValidationDate][formControlName],[androValidationDate][formControl],[androValidationDate][ngModel]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: forwardRef(() => ValidationDateDirective), multi: true }
  ]
})
export class ValidationDateDirective implements Validator {

  constructor() { }

  validate(c: AbstractControl): { [key: string]: any } {
    const value = c.value;

    // value not equal
    if (value && typeof value !== 'object') {
      return {
        androValidationDate: true
      };
    }
    return;
  }

}
