import { SafeResourceUrl } from '@angular/platform-browser';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
export class Organization {
  id: number;
  name: string;
  address: string;
  website: string;
  description: string;
  status = false;
  productsOrganization: Array<OrganizationProduct>;
  socialReason: string;
  garanceIdentification: string;
  siret: string;
  oriasNumber: string;
  phone: string;
  mail: string;
  representativeName: string;
  representativeFirstName: string;
  endDateMandate: NgbDateStruct;
  effectiveDateMandate: NgbDateStruct;
  fileBase64?: string | ArrayBuffer | SafeResourceUrl;
  extensionFile?: string;
  idLogo?: number;
}
export class OrganizationApi {
  address: string;
  description: string;
  endDateMandate: Date;
  effectiveDateMandate: Date;
  extension?: string;
  lastNameLegalRepresentative: string;
  firstNameLegalRepresentative: string;
  garanceIdentificationNumber: string;
  fileBase64?: string;
  id: number;
  name: string;
  website: string;
  status: boolean;
  productsOrganization: Array<OrganizationProduct>;
  socialReason: string;
  siretCode: string;
  oriasCode: string;
  phoneNumber: string;
  mail: string;
  idLogo?: number;
}

export class OrganizationProduct {
  id: number;
  productId: number;
  organizationId: number;
  active: boolean;
  productName: string;
}
