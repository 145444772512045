import { SafeResourceUrl } from '@angular/platform-browser';
import { NetworkApi } from './network';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Civility, ProfileType } from '../enums/enums';

export class User {
  id: number;
  lastName: string;
  firstName: string;
  brokerageCode: string;
  email: string;
  login: string;
  password: string;
  profileType: ProfileType;
  status = false;
  organizationId: number;
  civility: Civility;
  birthDate: NgbDateStruct;
  city: string;
  postalCode: string;
  phone: string;
  address: string;
  superiorHierarchicId: number;
  organizationName: string;
  fileBase64?: string | ArrayBuffer | SafeResourceUrl;
  extensionFile?: string;
  avatarId?: number;
}

export class UserApi {
  id: number;
  firstName: string;
  lastName: string;
  login: string;
  password: string;
  email: string;
  status: boolean;
  profilType: ProfileType;
  brokerageCode: string;
  organizationId: number;
  civility: Civility;
  birthDate: Date;
  city: string;
  postalCode: string;
  phone: string;
  address: string;
  superiorHiearchicId: number;
  networks?: Array<NetworkApi>;
  organizationName: string;
  // urlAvatar: string;
  fileBase64?: string | ArrayBuffer | SafeResourceUrl;
  extensionFile?: string;
  avatarId?: number;
}
