import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationDialogComponent } from '../../components';
import { ConfirmationDialogOptions } from 'sharedModels';


@Injectable()
export class ConfirmationDialogService {

  constructor(private modalService: NgbModal) { }

  public confirm(confirmationDialogOptions: ConfirmationDialogOptions): Promise<boolean> {
    const modalRef = this.modalService.open(ConfirmationDialogComponent, {
      size: confirmationDialogOptions.dialogSize,
      centered: true,
      backdrop: 'static'
    });
    modalRef.componentInstance.title = confirmationDialogOptions.title;
    modalRef.componentInstance.message = confirmationDialogOptions.message;
    modalRef.componentInstance.btnOkText = confirmationDialogOptions.btnOkText !== undefined ? confirmationDialogOptions.btnOkText : 'BUTTON.Yes';
    modalRef.componentInstance.btnCancelText = confirmationDialogOptions.btnCancelText !== undefined ?
      confirmationDialogOptions.btnCancelText : 'BUTTON.No';
    modalRef.componentInstance.dialogType = confirmationDialogOptions.dialogType !== undefined ? confirmationDialogOptions.dialogType : 'Warning';
    modalRef.componentInstance.clientObject = confirmationDialogOptions.clientObject;

    return modalRef.result;
  }

}
