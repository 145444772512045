import { UploadPageName, UploadPageSide } from './enums/enums';

export class ShowFile {
  title: string;
  Url: string;
  pageName: UploadPageName;
  pageSide: UploadPageSide;
  show: boolean;
  id?: number;
  picture?: any;

}
