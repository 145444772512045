import {
  AfterViewInit, Component, ContentChildren, ElementRef, forwardRef, Input, QueryList, ViewChild,
  ContentChild, TemplateRef, OnDestroy, ChangeDetectorRef, AfterViewChecked, Output, EventEmitter, ViewChildren
} from '@angular/core';
import { ActiveDescendantKeyManager } from '@angular/cdk/a11y';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CustomDropdownService } from '../../services/custom-dropdown.service';
import { DropdownComponent } from '../dropdown/dropdown.component';
import { CustomSelectOptionComponent } from '../custom-select-option/custom-select-option.component';
import { AndroSelectOptionSelectedDirective } from './../../directive/dropdown.directive';

import * as lodashNs from 'lodash';

const lodash = lodashNs;


@Component({
  selector: 'andro-custom-select',
  templateUrl: './custom-select.component.html',
  styleUrls: ['./custom-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomSelectComponent),
      multi: true
    },
    CustomDropdownService
  ]
})
export class CustomSelectComponent implements AfterViewInit, ControlValueAccessor, OnDestroy, AfterViewChecked {

  @Input() public label: string;
  @Output() public valueChange = new EventEmitter<string>();
  @Input() public placeholder: string;
  @Input() public selected: any;
  @Input() public required = false;
  @Input() public autoCompleat = false;
  @Input() public error = false;
  @Input() public disabled = false;


  @ViewChild('dropdown_options_container', { static: false }) protected choiceMenuElRef: ElementRef;

  @ViewChild('input') public input: ElementRef;

  @ViewChild(DropdownComponent) public dropdown: DropdownComponent;

  @ContentChildren(CustomSelectOptionComponent) public options: QueryList<CustomSelectOptionComponent>;

  @ContentChild(AndroSelectOptionSelectedDirective, { read: TemplateRef, static: true })
  templateOptionSelected: AndroSelectOptionSelectedDirective;

  public selectedOption: CustomSelectOptionComponent;
  private keyManager: ActiveDescendantKeyManager<CustomSelectOptionComponent>;

  public reference: HTMLElement;

  public displayText: string = null;
  autoCompleatValue: string;
  public optionsSelected: string;

  writeChangeValueAfterViewInit = false;

  public onChangeFn = (_: any) => { };

  public onTouchedFn = () => { };

  constructor(
    private dropdownService: CustomDropdownService,
    private cd: ChangeDetectorRef) {
    this.dropdownService.register(this);
  }

  public ngAfterViewInit() {
    setTimeout(() => {
      this.writeChangeValueAfterViewInit = true;
      this.selectedOption = this.options.toArray().find(option => lodash.isEqual(option.value, this.selected));

      this.displayText = this.selectedOption ? this.selectedOption.displayText : '';
      this.optionsSelected = this.selectedOption ? this.selectedOption.value : '';
      this.keyManager = new ActiveDescendantKeyManager(this.options)
        .withHorizontalOrientation('ltr')
        .withVerticalOrientation()
        .withWrap();
    }, 0);
  }

  ngAfterViewChecked() {
    this.cd.detectChanges();
  }

  ngOnDestroy() {
  }

  public showDropdown(reference: HTMLElement) {
    if (!this.disabled) {
      this.reference = reference;
      setTimeout(() => {
        this.dropdown.show();
        setTimeout(() => {
          this.input?.nativeElement.focus();
        });

        if (this.options.length) {
          // this.selected ? this.keyManager.setActiveItem(this.selectedOption) : this.keyManager.setFirstItemActive();
          if (this.selected) {
            this.keyManager.setActiveItem(this.selectedOption);
          }
          setTimeout(() => {
            const element = this.choiceMenuElRef.nativeElement.querySelector('andro-custom-select-option.selected') as HTMLLinkElement;
            if (element) {
              element.scrollIntoView({ block: 'center', inline: 'nearest' });
            }
          });
        }
      }, 0);
    }
  }

  public hideDropdown() {
    this.dropdown.hide();
  }

  public onKeyDown(event: KeyboardEvent) {
    if (['Enter', ' ', 'ArrowDown', 'Down', 'ArrowUp', 'Up'].indexOf(event.key) > -1) {
      if (!this.dropdown.showing) {
        this.showDropdown(this.reference);
        return;
      }

      if (!this.options.length) {
        event.preventDefault();
        return;
      }
    }

    // if (event.key === 'Enter' || event.key === ' ') {
    if (event.key === 'Enter') {
      this.selectedOption = this.keyManager.activeItem;
      this.selected = this.selectedOption?.value;
      this.displayText = this.selectedOption ? this.selectedOption.displayText : '';
      this.hideDropdown();
      this.onChange();
    } else if (event.key === 'Escape' || event.key === 'Esc') {
      // this.dropdown.showing && this.hideDropdown();
      // this.hideDropdown();
    } else if (['ArrowUp', 'Up', 'ArrowDown', 'Down', 'ArrowRight', 'Right', 'ArrowLeft', 'Left']
      .indexOf(event.key) > -1) {
      this.keyManager.onKeydown(event);
    } else if (event.key === 'PageUp' || event.key === 'PageDown' || event.key === 'Tab') {
      // this.dropdown.showing && event.preventDefault();
      event.preventDefault();
    } else {
      const inp = String.fromCharCode(event.keyCode);
      if (/[a-zA-Z0-9-_ ]/.test(inp)) {
        const element = this.options.find((item, index) => item.displayText.toUpperCase().startsWith(event.key.toUpperCase()));
        if (element) {
          this.keyManager.setActiveItem(element);
        }
      }
      // if (event.keyCode >= 48 && event.keyCode <= 57) {
      //   alert("input was 0-9");
      // }
      // if (event.keyCode >= 65 && event.keyCode <= 90) {
      //   alert("input was a-z");
      // }
    }
  }

  public selectOption(option: CustomSelectOptionComponent) {
    this.autoCompleatValue = null;
    this.keyManager.setActiveItem(option);
    this.selected = option.value;
    this.optionsSelected = option.value;
    this.selectedOption = option;
    this.displayText = this.selectedOption ? this.selectedOption.displayText : '';
    this.hideDropdown();
    this.input?.nativeElement.focus();
    this.onChange();
  }

  public registerOnChange(fn: any): void {
    this.onChangeFn = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouchedFn = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public writeValue(obj: any): void {
    this.selected = obj;
    if (this.writeChangeValueAfterViewInit) {
      this.selectedOption = this.options.toArray().find(option => lodash.isEqual(option.value, this.selected));
      this.displayText = this.selectedOption ? this.selectedOption.displayText : '';

      if (this.options.length) {
        this.selected ? this.keyManager.setActiveItem(this.selectedOption) : this.keyManager.setFirstItemActive();
      }
    }
  }

  public onTouched() {
    this.onTouchedFn();
  }

  public onChange() {
    this.onChangeFn(this.selected);
  }
}
