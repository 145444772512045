import { NgModule, InjectionToken } from '@angular/core';

export let APP_CONFIG = new InjectionToken<AppConfig>('app.config');

const BASE_URL = document.location.hostname === 'localhost' || document.location.hostname.includes('garance-mutuelle.fr') ? '' : 'https://api.andromeda.novhadrim.com';

export class AppConfig {
  baseSubscriptionUrl: string;
  baseUploadUrl: string;
  baseCollectionNeedsUrl: string;
  baseManagementUrl: string;
  apiUrl: string;
  baseOauthUrl: string;
  baseYousignUrl: string;
}

export const APP_DI_CONFIG: AppConfig = {
  baseYousignUrl: 'https://staging-api.yousign.com/',
  baseSubscriptionUrl: `${BASE_URL}/souscription-service/`,
  baseUploadUrl: `${BASE_URL}/upload-service/`,
  baseCollectionNeedsUrl: `${BASE_URL}/cupreco-service/`,
  baseOauthUrl: `${BASE_URL}/oauth-service/uaa`,
  baseManagementUrl: `${BASE_URL}/ouman-service/`,
  apiUrl: ''
};

@NgModule({
  providers: [{
    provide: APP_CONFIG,
    useValue: APP_DI_CONFIG
  }]
})
export class AppConfigModule { }
