export enum UploadCategoryName {
  PiecesIdentity = 'PIECES_IDENTITY',
  Iban = 'IBAN'
}

export enum UploadPageName {
  Cin = 'CIN',
  Passport = 'PASSPORT',
  StayCard = 'RESIDENCE_CARD',
  Other = 'OTHER',
}

export enum UploadPageSide {
  Recto = 'RECTO',
  Verso = 'VERSO',
  Page = 'PAGE',
}

export enum ProfileType {
  Inspector = 'INSPECTOR',
  Director = 'DIRECTOR',
  Administrator = 'ADMINISTRATOR',
  Advisor = 'ADVISOR',
  Supervisor = 'SUPERVISOR',
  Hypervisor = 'HYPERVISOR'
}

export enum SignatureZoneCategoryName {
  Ba = 'BA',
  Sepa = 'SEPA',
  DutyToAdvise = 'DUTY_TO_ADVICE',
  CollectionNeeds = 'KYC',
}

export enum Civility {
  Autre = 'OTHER',
  Madam = 'MADAM',
  Sir = 'SIR',
}

export enum TypeNetwork {
  Animation =  'ANIMATION',
  Hierarchical =  'HIERARCHICAL',
}
