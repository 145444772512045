import { UploadPageSide, UploadCategoryName, UploadPageName } from './enums/enums';

export class FileResponse {
  categoryName: UploadCategoryName;
  clientId: number;
  description: string;
  pageName: UploadPageName;
  pageSide: UploadPageSide;
  refFile: string;
  subscriptionId: number;
  fileName: string;
  id: number;
  uploadMode: string;
  uriFile: string;
  picture?: any;
}

export class FileResponseApi {
  categoryName: UploadCategoryName;
  clientId: number;
  description: string;
  pageName: UploadPageName;
  pageSide: UploadPageSide;
  refFile: string;
  subscriptionId: number;
  fileName: string;
  id: number;
  uploadMode: string;
  uriFile: string;
}
