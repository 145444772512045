import { StorageService } from './storage/storage.service';
import { HttpConfigInterceptor } from './api/httpconfig-interceptor/httpconfig.interceptor';
import { BaseHttpService } from './api/base/base-http.service';
import { SharedService } from './api/shared/shared.service';
import { AuthGuardService } from './guards/auth-guard.service';
import { DatepickerI18nService } from './ng-bootstrap/datepicker-i18n.service';
import { NgbDateCustomParserFormatter } from './ng-bootstrap/ngb-date-custom-parser-formatter';
import { ConfirmationDialogService } from './confirmation-dialog.service/confirmation-dialog.service';
import { UploadService } from './api/upload/upload.service';

export const services: any[] = [
  StorageService,
  HttpConfigInterceptor,
  BaseHttpService,
  SharedService,
  AuthGuardService,
  DatepickerI18nService,
  NgbDateCustomParserFormatter,
  ConfirmationDialogService,
  UploadService
];

export * from './storage/storage.service';
export * from './api/httpconfig-interceptor/httpconfig.interceptor';
export * from './api/base/base-http.service';
export * from './api/shared/shared.service';
export * from './guards/auth-guard.service';
export * from './ng-bootstrap/datepicker-i18n.service';
export * from './ng-bootstrap/ngb-date-custom-parser-formatter';
export * from './confirmation-dialog.service/confirmation-dialog.service';
export * from './api/upload/upload.service';
