import { HttpParams } from '@angular/common/http';


export class HttpRequestParams<T> {
  // authorization: false;
  urlParams: string | number;
  payload: any;
  queryParams: string | T | { [key: string]: string | string[] };
  responseType: any;
}

export class HttpRequestOptions {
  body?: any;
  observe?: 'body';
  params?: HttpParams | {
    [param: string]: string | string[];
  };
  responseType?: any;
  reportProgress?: boolean;
  withCredentials?: boolean;
}
