import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
export class OrganizationDto {
  socialReason: any;
  oriasNumber: any;
  name: string;
  id: number;
  garanceIdentification: any;
  representativeName: any;
  representativeFirstName: any;
  endDateMandate: NgbDateStruct;
  effectiveDateMandate: NgbDateStruct;
  urlLogo: string;
}

export class OrganizationDtoApi {
  socialReason: any;
  oriasCode: any;
  name: string;
  lastNameLegalRepresentative: string;
  id: number;
  garanceIdentificationNumber: any;
  firstNameLegalRepresentative: string;
  endDateMandate: Date;
  effectiveDateMandate: Date;
  urlLogo: string;
}
