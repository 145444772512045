import { Highlightable } from '@angular/cdk/a11y';
import { Component, HostBinding, HostListener, Input, ElementRef, ContentChild, TemplateRef } from '@angular/core';
import { CustomSelectComponent } from '../custom-select/custom-select.component';
import { CustomDropdownService } from '../../services/custom-dropdown.service';
import { AndroSelectOptionDirective } from '../../directive/dropdown.directive';

@Component({
  selector: 'andro-custom-select-option',
  template: `
              <h1 *ngIf="!templateOption" >{{displayText}}</h1>
              <ng-container [ngTemplateOutlet]="templateOption"></ng-container>`,
  styleUrls: ['./custom-select-option.component.scss']
})
export class CustomSelectOptionComponent implements Highlightable {

  // @Input()
  // public key: string;

  @Input()
  public value: any;

  @Input()
  public displayText: string;

  @ContentChild(AndroSelectOptionDirective, { read: TemplateRef, static: true }) templateOption: AndroSelectOptionDirective;

  @HostBinding('class.selected')
  public get selected(): boolean {
    return this.select.selectedOption === this;
  }

  @HostBinding('class.active')
  public active = false;

  public select: CustomSelectComponent;

  constructor(
    private dropdownService: CustomDropdownService,
    private element: ElementRef) {
    this.select = this.dropdownService.getSelect();
  }


  public setActiveStyles(): void {
    this.active = true;
    this.element.nativeElement.scrollIntoView({ block: 'center', inline: 'nearest' });
  }

  public setInactiveStyles(): void {
    this.active = false;
  }

  @HostListener('click', ['$event'])
  public onClick(event: UIEvent) {
    event.preventDefault();
    event.stopPropagation();

    this.select.selectOption(this);

  }
}
