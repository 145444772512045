import { Component, OnInit, Input } from '@angular/core';
import { IItemsAndroListObject } from 'sharedModels';


@Component({
  selector: 'andro-list-object',
  templateUrl: './andro-list-object.component.html',
  styleUrls: ['./andro-list-object.component.scss']
})

export class AndroListObjectComponent implements OnInit {

  @Input() items: Array<IItemsAndroListObject>;

  constructor() { }

  ngOnInit(): void {
  }

}
