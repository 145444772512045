import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse
} from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { StorageService } from '../../storage/storage.service';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
  constructor(private router: Router, private storageService: StorageService) { }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    //   if (token) {
    //     request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + token) });
    // }

    // if (!request.headers.has('Content-Type')) {
    //   request = request.clone({ headers: request.headers.set('Authorization', 'Token 2d4bb3e0e1f4b9429bce2d1bab63400801767557') });
    // }

    // request = request.clone({ headers: request.headers.set('Accept', 'application/json; charset=utf-8') });

    // console.log('request', request);
    // next.handle(request).pipe(catchError((error, caught) => {
    //   // intercept the respons error and displace it to the console
    //   this.handleAuthError(error);
    //   return of(error);
    // }) as any);

    return next.handle(request).pipe(
      tap(
        event => {
          if (event instanceof HttpResponse) {
            // http response status code
            // console.log('event.status', event);
          }
        },
        error => {
          // http response status code
          // console.log('----response----');
          // console.error('status code:');
          // console.error('error.status', error);
          if (error.status === 401) {
            this.storageService.clear();
            this.router.navigate([`/auth/login`]);
          }
          // console.error(error.message);
          // console.log('--- end of response---');
        }
      )
    );
  }

  //   private handleAuthError(err: HttpErrorResponse): Observable<any> {
  //     // handle your auth error or rethrow
  //     console.log('handled error ' + err);
  //     if (err.status === 401) {
  //       // navigate /delete cookies or whatever
  //       // this.router.navigate([`/login`]);
  //       // if you've caught / handled the error, you don't want to rethrow
  //       // it unless you also want downstream consumers to have to handle it as well.
  //       return of(err.message);
  //     }
  //     // throw error;
  //   }
}
