
import { Directive, forwardRef, Attribute, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS } from '@angular/forms';
@Directive({
  selector: '[androRange][formControlName],[androRange][formControl],[androRange][ngModel]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: forwardRef(() => RangeValidatorDirective), multi: true }
  ]
})
export class RangeValidatorDirective {

  @Input() androRangeMax: number;
  @Input() androRangeMin: number;

  constructor() { }

  validate(c: AbstractControl): { [key: string]: any } {
    const value = + c.value;
    if (!!this.androRangeMin && !!this.androRangeMax) {
      if (Number(this.androRangeMin) <= value && value <= Number(this.androRangeMax)) {
        return;
      }
      return {
        androRange: true
      };
    } else if (!!this.androRangeMin && !this.androRangeMax) {
      if (Number(this.androRangeMin) <= value) {
        return;
      }
      return {
        androRange: true
      };
    } else if (!this.androRangeMin && !!this.androRangeMax) {
      if (value <= Number(this.androRangeMax)) {
        return;
      }
      return {
        androRange: true
      };
    }
    return;
  }

}
