import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: 'input[androDecimalsOnly]',
})
export class DecimalDirective {
  constructor(private elementRef: ElementRef) {}

  @HostListener('input', ['$event']) onInputChange(event) {
    const initialValue = this.elementRef.nativeElement.value;
    const pattern = new RegExp('(^[0-9]{1,11})+(.,[0-9]{1,2})?$', 'g');
    let newInput = initialValue.replace(/[^0-9,.]/g, '').replace(',', '.');
    if (initialValue === '') {
      return '';
    }
    const dotPattern = /^[.]*$/;
    if (dotPattern.test(initialValue)) {
      return '';
    }
    let dotCount;
    const newInputLength = newInput.length;
    if (pattern.test(newInput)) {
      dotCount = newInput.split('.').length - 1; // count of dots present
      if (dotCount === 0 && newInputLength > 11) {
        // condition to restrict "integer part" to 9 digit count
        newInput = newInput.slice(0, newInputLength - 1);
      }
    } else {
      // pattern failed
      dotCount = newInput.split('.').length - 1; // count of dots present
      if (newInputLength > 0 && dotCount > 1) {
        // condition to accept min of 1 dot
        newInput = newInput.slice(0, newInputLength - 1);
      }
      if (newInput.slice(newInput.indexOf('.') + 1).length > 2) {
        // condition to restrict "fraction part" to 4 digit count only.
        newInput = newInput.slice(0, newInputLength - 1);
      }
      this.elementRef.nativeElement.value = newInput;
      if (initialValue !== this.elementRef.nativeElement.value) {
        event.stopPropagation();
      }
    }
  }
}
