import { Component, Input } from '@angular/core';
import { HeaderItem } from '../../models/header-item';
import { Router } from '@angular/router';
import { StorageService } from '../../services/storage/storage.service';
import { AuthService } from 'src/app/modules/shared/services/api/auth/auth.service';

@Component({
  selector: 'andro-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {

  @Input() listNavBar: Array<HeaderItem>;

  listParam = [
    { title: 'Profile', icon: 'icon-person-done' },
    { title: 'Param', icon: 'icon-settings' },
    { title: 'LogOut', icon: 'icon-power-outline', color: 'danger-color' },
  ];

  selectedParam: 'Profile' | 'Param' | 'LogOut';

  constructor(
    public router: Router,
    public storageService: StorageService,
    private authService: AuthService
  ) { }

  navigateTo(headerItem: HeaderItem) {
    if (headerItem.navigable && !!headerItem.route) {
      this.router.navigateByUrl(headerItem.route);
    }
  }

  selectParam() {
    switch (this.selectedParam) {
      case 'Profile':
        break;
      case 'Param':
        break;
      case 'LogOut':
        this.logOut();
        break;
    }
  }

  logOut() {
    this.authService.logOut();
  }
}
