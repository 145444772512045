<div class="dialog-confirm-container">
  <div class="dialog-confirm-container-body"
    [ngClass]="{'body-warning': dialogType == 'Warning', 'body-alert': dialogType == 'Alert'}">
    <i [ngClass]="{'icon-info-outline icon-warning': dialogType == 'Warning',
    ' icon-alert icon-trash': dialogType == 'Alert'}"></i>
    <div class="andro-mb-15">
      <h1 class="andro-mb-5">{{title| translate}}</h1>
      <h5>{{message| translate}}</h5>
    </div>
    <ng-container *ngIf="clientObject?.length">
      <andro-list-object [items]="clientObject"></andro-list-object>
    </ng-container>
  </div>
  <div class="dialog-confirm-container-footer">
    <div class="dialog-confirm-container-footer-button-yes" (click)="accept()"
      [ngClass]="{'button-yes-warning': dialogType == 'Warning', 'button-yes-alert': dialogType == 'Alert'}">
      <h5>{{btnOkText| translate}}</h5>
    </div>
    <div class="dialog-confirm-container-footer-button-no" (click)="dismiss()"
      [ngClass]="{'button-no-alert': dialogType == 'Alert'}">
      <h5>{{btnCancelText| translate}}</h5>
    </div>
  </div>

</div>
