import { Injectable } from '@angular/core';
import {LocalStorage, LocalStorageService} from 'ngx-webstorage';
import { User } from '../../models/entities/user';

@Injectable()
export class StorageService {

  constructor(private storage: LocalStorageService) { }

  @LocalStorage()
  accessToken: string;

  @LocalStorage()
  expiresDateToken: Date;

  @LocalStorage()
  user: User;

  clear(): void {
    this.storage.clear();
  }

}
