import { HttpClient } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';

import { BaseHttpService } from '../base/base-http.service';
import { APP_CONFIG, AppConfig } from 'sharedConfig';
import { StorageService } from '../../storage/storage.service';
import { HttpRequestParams } from '../../../models/http-request';

import { Observable } from 'rxjs';

@Injectable()
export class UploadService extends BaseHttpService {
  private uploadUrl = 'document64/';
  private download = '/all';

  constructor(
    @Inject(APP_CONFIG) protected config: AppConfig,
    protected storageService: StorageService,
    protected http: HttpClient
  ) {
    super(config, storageService, http);
  }


  getRequestUrl(endPoint: string) {
    super.getRequestUrl(endPoint);
    return `${this.config.baseUploadUrl}${endPoint}`;
  }


  getDocument64(idLogo: number): Observable<{extension: string,fileBase64: string}> {
    return this.get<{extension: string,fileBase64: string}>(this.uploadUrl + idLogo + this.download, true);
  }​​
}
