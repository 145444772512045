<div class="breadcrumbs">
  <div class="title-menu">
    <img src="../../../assets/images/logo-andromeda.svg" alt="">
  </div>
  <div class="breadcrumb-list">
    <ul class="icons-list" [ngClass]="{'process': listNavBar[0].process}">
      <li class="list-item" *ngFor="let itemList of listNavBar; let index= index" (click)="navigateTo(itemList)"
        [ngClass]="{'list-item-active': router.isActive(itemList.route, false)|| itemList.active ,
         'navigable': itemList.navigable && !!itemList.route,'list-item-valid': itemList.valid && index!=0,
         'icons-list-flex-fill': itemList.process&& index!=0&& index!=listNavBar.length-1,
         'list-item-border-none': itemList.process && index==0}" [hidden]="!itemList.title&&!itemList.icon"
        id="header_item_{{itemList.title}}">
        <i [class]="itemList.icon" aria-hidden="true"></i>
        <h5 *ngIf="itemList.title">{{itemList.title | translate}}</h5>
      </li>
      <li class="list-item" #iconProfile (click)="androCustomSelect.showDropdown(iconProfile)">
        <div class="andro-pl-14">
          <h6>{{'HomeNavbar.Welcome' | translate}}</h6>
          <h4>{{storageService.user?.firstName}}</h4>
        </div>
        <i class="icon-arrow-ios-downward-outline"></i>
        <andro-custom-select #androCustomSelect name="selectedParam" [(ngModel)]="selectedParam"
          (ngModelChange)="selectParam()">
          <!-- content list -->
          <andro-custom-select-option *ngFor="let item of listParam" [value]="item.title"
            [displayText]="'AndroHeader.' + item.title | translate">
            <ng-template androSelectOption>
              <div class="header-params">
                <i class="{{item.icon}} {{item.color}}"></i>
                <h1 class="{{item.color}}">{{'AndroHeader.' + item.title | translate}}</h1>
              </div>
            </ng-template>
          </andro-custom-select-option>
        </andro-custom-select>
      </li>
    </ul>
  </div>

</div>
