import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';


import { APP_CONFIG, AppConfig } from 'sharedConfig';
import { HttpRequestParams, User, UserApi, SearchUserModel } from 'sharedModels';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseHttpService } from 'src/app/modules/shared/services/api/base/base-http.service';
import { StorageService } from 'src/app/modules/shared/services/storage/storage.service';
import { NgbDateNativeAdapter } from '@ng-bootstrap/ng-bootstrap';

@Injectable({ providedIn: 'root' })
export class UserService extends BaseHttpService {

  private routeUsersUrl = 'users';
  private changePasswordUrl = '/change-password';
  private currentUserUrl = 'users/user';
  private searchUrl = '/search';

  constructor(
    @Inject(APP_CONFIG) protected config: AppConfig,
    protected storageService: StorageService,
    protected http: HttpClient,
    private parserFormatter: NgbDateNativeAdapter

  ) {
    super(config, storageService, http);
  }

  getAllUsers(): Observable<Array<User>> {
    return this.get<Array<UserApi>>(this.routeUsersUrl, true).pipe(
      map(users => users.map(user => this.mapUserApiToUser(user)))
    );
  }

  postUser(user: User): Observable<User> {
    const httpRequestParams = new HttpRequestParams();
    httpRequestParams.payload = this.mapUserAToUserApi(user);
    return this.post<UserApi>(this.routeUsersUrl, true, httpRequestParams).pipe(
      map(userItem => this.mapUserApiToUser(userItem))
    );
  }

  putUser(user: User): Observable<User> {
    const httpRequestParams = new HttpRequestParams();
    httpRequestParams.payload = this.mapUserAToUserApi(user);
    return this.put<UserApi>(this.routeUsersUrl, true, httpRequestParams).pipe(
      map(userItem => this.mapUserApiToUser(userItem))
    );
  }

  changePassword(user: User): Observable<any> {
    const httpRequestParams = new HttpRequestParams();
    httpRequestParams.payload = this.mapUserAToUserApi(user);
    return this.post<any>(this.routeUsersUrl + this.changePasswordUrl, true, httpRequestParams);
  }

  getUserCredential(login: string) {
    const httpRequestParams = new HttpRequestParams();
    httpRequestParams.queryParams = { login };
    return this.get<UserApi>(this.currentUserUrl, true, httpRequestParams).pipe(
      map(user => this.mapUserApiToUser(user))
    );
  }

  searchUsers(searchUserModel: SearchUserModel): Observable<Array<User>> {
    const httpRequestParams = new HttpRequestParams();
    httpRequestParams.payload = searchUserModel;
    return this.post<Array<UserApi>>(this.routeUsersUrl + this.searchUrl, true, httpRequestParams).pipe(
      map(users => users.map(user => this.mapUserApiToUser(user)))
    );
  }

  mapUserApiToUser(user: UserApi): User {
    return {
      id: user.id,
      brokerageCode: user.brokerageCode,
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      login: user.login,
      password: user.password,
      profileType: user.profilType,
      status: user.status,
      organizationId: user.organizationId,
      civility: user.civility,
      birthDate: user.birthDate ? this.parserFormatter.fromModel(new Date(user.birthDate)) : null,
      city: user.city,
      postalCode: user.postalCode,
      phone: user.phone,
      address: user.address,
      superiorHierarchicId: user.networks?.length > 0 ? user.networks[0].superiorHiearchicId : null,
      organizationName: user.organizationName,
      avatarId: user.avatarId,
      // urlAvatar: user.urlAvatar
    };
  }

  mapUserAToUserApi(user: User): UserApi {
    return {
      id: user.id,
      brokerageCode: user.brokerageCode,
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      login: user.login,
      password: user.password,
      profilType: user.profileType,
      status: user.status,
      organizationId: user.organizationId,
      civility: user.civility,
      birthDate: user.birthDate ? this.parserFormatter.toModel(user.birthDate) : null,
      city: user.city,
      postalCode: user.postalCode,
      phone: user.phone,
      address: user.address,
      superiorHiearchicId: user.superiorHierarchicId,
      organizationName: user.organizationName,
      fileBase64: (user.fileBase64 && user.extensionFile) ?
        user.fileBase64.toString().replace(
          user.fileBase64.toString().substring(0, user.fileBase64.toString().indexOf(';base64') + 8), '') : '',
      extensionFile: user.extensionFile,
      avatarId: user.avatarId,
    };
  }
}
