import { Directive, TemplateRef } from '@angular/core';

@Directive({ selector: '[androSelectOption]' })
export class AndroSelectOptionDirective {
  constructor(public template: TemplateRef<any>) {
  }
}

@Directive({ selector: '[androSelectOptionSelected]' })
export class AndroSelectOptionSelectedDirective {
  constructor(public template: TemplateRef<any>) {
  }
}

@Directive({ selector: '[androSelectOptionNotFound]' })
export class AndroSelectOptionNotFoundDirective {
  constructor(public template: TemplateRef<any>) {
  }
}
