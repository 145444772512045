import { SignatureZoneCategoryName } from './enums/enums';
// llx,lly,urx,ury
export class FilesZone {
  id: number;
  page: number;
  // llx
  x: number;
  // lly
  y: number;
  // urx
  height: number;
  // ury
  width: number;
  mention1: string;
  mention2: string;
  reason: string;
  documentCategory: SignatureZoneCategoryName;
}
