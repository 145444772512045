import { Directive, forwardRef, Attribute } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';
@Directive({
  selector: '[androValidInputLength][formControlName],[androValidInputLength][formControl],[androValidInputLength][ngModel]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: forwardRef(() => AndroLengthValidatorDirective), multi: true }
  ]
})
export class AndroLengthValidatorDirective implements Validator {
  constructor(@Attribute('androValidInputLength') public androValidInputLength: string) { }

  validate(c: AbstractControl): { [key: string]: any } {
    const value = c.value;

    // value not equal
    if (String(value).length !== Number(this.androValidInputLength)) {
      return {
        androValidInputLength: true
      };
    }
    return;
  }
}
