import { Injectable, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';


import { APP_CONFIG, AppConfig } from 'sharedConfig';
import { User, HttpRequestParams } from 'sharedModels';
import { LoginResponse } from '../../../../authentication/models/login-response';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { UserService } from './../../../../home/services/user/user.service';
import { BaseHttpService } from '../base/base-http.service';
import { StorageService } from '../../storage/storage.service';


@Injectable()
export class AuthService extends BaseHttpService {

  loginUrl = '/oauth/token';
  logOutUrl = '/api/users/approval/revoke';

  grantType = 'password';
  clientId = 'browser';
  clientSecret = 'user';

  constructor(
    @Inject(APP_CONFIG) protected config: AppConfig,
    protected storageService: StorageService,
    protected http: HttpClient,
    private userService: UserService,
    private router: Router

  ) {
    super(config, storageService, http);
  }

  getRequestUrl(endPoint: string) {
    super.getRequestUrl(endPoint);
    return `${this.config.baseOauthUrl}${endPoint}`;
  }

  getHeaders(authorize?: boolean): HttpHeaders {
    let headers = new HttpHeaders({ Accept: 'application/json' });
    if (authorize) {
      headers = headers.set(
        'authorization',
        'Bearer ' + this.storageService.accessToken,
      );
    } else {
      headers = headers
        .set('Content-type', 'application/x-www-form-urlencoded; charset=utf-8')
        .set('Authorization', 'Basic ' + btoa(this.clientId + ':' + this.clientSecret));
    }
    return headers;
  }

  login(userName: string, password: string): Observable<User> {
    const httpRequestParams = new HttpRequestParams();
    httpRequestParams.queryParams = { grant_type: this.grantType, username: userName, password };

    return this.post<LoginResponse>(this.loginUrl, false, httpRequestParams).pipe(
      mergeMap(result => {
        this.storageService.accessToken = result.access_token;
        this.storageService.expiresDateToken = new Date(new Date().getTime() + (1000 * result.expires_in));
        return this.userService.getUserCredential(userName);
      })
    );
  }

  logOut() {
    const httpRequestParams = new HttpRequestParams();
    httpRequestParams.payload = { userId: this.storageService.user.login, clientId: this.clientId };
    this.post(this.logOutUrl, true, httpRequestParams).subscribe(result => {
      this.storageService.clear();
      this.router.navigate([`/auth/login`]);
    });
  }

}
